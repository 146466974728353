











































































import { Component, Prop, Vue } from "vue-property-decorator";
import FscModal from "@/components/Modal/FscModal.vue";
import FilterButton from "@/components/Button/FilterButton.vue";
import AbortButton from "@/components/Button/AbortButton.vue";
import { IPaymentWorkflowFilter } from "@/interfaces/IPaymentWorkflowFilter";
import { namespace } from "vuex-class";
import { LivePayPaymentStatusEnum } from "@/enums/LivePayPaymentStatusEnum";
import FscInputDecimal from "@/components/Field/FscInputDecimal.vue";

const LicenseClassModule = namespace("license-class");

@Component({
  components: { FscInputDecimal, AbortButton, FilterButton, FscModal },
})
export default class PaymentWorkflowFilterModal extends Vue {
  public name = "PaymentWorkflowFilterModal";

  @Prop({ required: true, type: String })
  protected modalId!: string;

  @Prop({ required: true })
  public paymentWorkflowFilter!: IPaymentWorkflowFilter;

  @Prop({ required: true, default: () => false })
  public appliedFilter!: boolean;

  @LicenseClassModule.Action("findAll")
  public findAllLicenseClassesAction: any;

  @LicenseClassModule.Getter("getDataList")
  public allLicenseClasses: any;

  protected ok(): void {
    this.$emit("update:appliedFilter", true);
    this.$emit("filter");
  }

  private clear() {
    this.$emit("update:paymentWorkflowFilter", {} as IPaymentWorkflowFilter);
    this.$emit("update:appliedFilter", false);
    this.$emit("filter");
  }

  public get licenseClasses(): Array<any> {
    return this.allLicenseClasses;
  }

  public mounted() {
    this.findAllLicenseClassesAction({ resource: "license-classes" });
  }

  public get statuses() {
    return [
      {
        color: "#dc0c23",
        value: LivePayPaymentStatusEnum.RED,
      },
      {
        color: "#F9B342",
        value: LivePayPaymentStatusEnum.YELLOW,
      },
      {
        color: "#17a533",
        value: LivePayPaymentStatusEnum.GREEN,
      },
    ];
  }
}
