
















import { Component, Vue } from "vue-property-decorator";

@Component
export default class FscCard extends Vue {
  public name = "FscCard";

  public get hasHeaderSlot(): boolean {
    return !!this.$slots.header;
  }

  public get hasFooterSlot(): boolean {
    return !!this.$slots.footer;
  }
}
